/* Global styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: white;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: white;
  border-bottom: 2px solid #e0e0e0;
}

.header-left {
  display: flex;
  align-items: center;
}

.logo {
  width: 60px;
  height: auto;
  margin-right: 15px;
}

.company-name {
  font-size: 32px;
  color: #000000;
}

.navbar a {
  margin-left: 20px;
  color: #005233;
  text-decoration: none;
  font-weight: bold;
}

.navbar a:hover {
  text-decoration: underline;
}


.main-content {
  text-align: center;
  padding: 40px;
}

.hero-section {
  background-color: #005233;
  color: white;
  padding: 60px 0;
}

.hero-text {
  font-size: 36px;
  font-weight: bold;
  max-width: 900px;
  margin: 0 auto;
}

.contact-section {
  display: flex;
  justify-content: space-around;
  padding: 40px 0;
  background-color: white;
}

.contact-column {
  text-align: left;
}

.contact-column h2 {
  color: #005233;
}


/* Footer Styling */
.footer {
  background-color: #434343;
  color: white;
  padding: 20px 40px;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo-section {
  display: flex;
  align-items: center;
}

.footer-logo {
  width: 80px;
  height: auto;
  margin-right: 20px;
}

.footer-links-section {
  display: flex;
  justify-content: center;
}

.footer-nav {
  display: flex;
  gap: 30px;
}

.footer-nav a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.footer-nav a:hover {
  text-decoration: underline;
}

.footer-bottom {
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 10px;
  font-size: 14px;
}

/* Ensure the navbar's general styles don't affect the login button */
.navbar .login-button {
  background-color: #b01c1c;  /* Red background */
  color: white;  /* White text */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.navbar .login-button:hover {
  background-color: #930e0e; /* Darker red on hover */
  color: white;  /* Ensure text stays white on hover */
}
