/* Home Page Styling */
.home-page {
    padding: 40px;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .hero-section {
    background-color: #005233;
    color: white;
    text-align: left;
    padding: 60px 0;
    margin-bottom: 40px;
  }
  
  .hero-text {
    font-size: 48px;
    font-weight: bold;
  }
  
  .hero-subtext {
    font-size: 20px;
    margin-top: 10px;
  }
  
  .home-content {
    font-size: 18px;
    line-height: 1.6;
    color: #333;
    text-align: left;
  }
  
  .home-content p {
    margin-bottom: 20px;
  }
  