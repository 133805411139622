/* Contact Page */
.contact-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
  }
  
  .contact-page h2 {
    color: #005233;
    text-align: left;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .contact-page p {
    text-align: left;
    margin-bottom: 30px;
    color: #333;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    text-align: left;
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
  }
  
  .form-group input, 
  .form-group textarea, 
  .form-group select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    right: 10px;
  }
  
  .form-group input:focus, 
  .form-group textarea:focus, 
  .form-group select:focus {
    border-color: #005233;
    outline: none;
  }
  
  textarea {
    height: 100px;
    resize: none;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #b01c1c;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #930e0e;
  }
  