/* solutions Page Styling */
.solutions-page {
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .solutions-title {
    font-size: 36px;
    color: #005233;
    text-align: left;
    margin-bottom: 40px;
  }
  
  .solutions-list {
    font-size: 18px;
    line-height: 1.8;
    color: #333;
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
  }
  
  .solutions-list li {
    margin-bottom: 20px;
  }
  