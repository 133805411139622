/* Login Page Styling */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('/public/background.png') center/cover no-repeat; /* Replace with your background image */
  }
  
  .login-box {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .login-title {
    font-size: 24px;
    color: #b01c1c;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    color: #333;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
/* Password container styles */
.password-container {
    position: relative; /* Needed to allow the icon to be positioned inside the container */
  }
  
  .password-container input {
    width: 100%;
    padding-right: 10px; /* Ensure enough space for the eye icon on the right */
  }
  
  .password-container i {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%); /* Center the icon vertically */
    cursor: pointer;
    font-size: 18px;
    color: #555;
  }
  
  .password-container i:hover {
    color: #000;
  }
  
  /* Other form styles */

  
  /* Forgot password link */
  .forgot-password {
    text-align: center;
    margin-top: 10px;
  }
  
  .forgot-password a {
    color: #b01c1c;
    text-decoration: none;
  }
  
  .forgot-password a:hover {
    text-decoration: underline;
  }
  
  /* Additional styles */
  .login-info {
    margin-top: 30px;
    font-size: 14px;
    color: #555;
  }
  
  .login-info ul {
    list-style-type: disc;
    margin: 10px 0 0 20px;
  }
  
  .login-info ul li {
    margin-bottom: 10px;
  }

  /* Styling for the Login Button */
.login-button-page {
    background-color: #b01c1c;  /* Red background */
    color: white;  /* White text */
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
  }
  
  .login-button-page:hover {
    background-color: #930e0e; /* Darker red on hover */
    color: white;  /* Ensure text stays white on hover */
  }
  